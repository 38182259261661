import Layout from "components/Layout";
import CouponDiv from "./CouponDiv";
import Barcode from "react-barcode";
import Button from "components/Inputs/Button";
import { useModal } from "utils/hookedComponents/Modal/core/modal.hook";
import Modal from "utils/hookedComponents/Modal";
import ModalCollectFeedback from "components/Modals/ModalCollectFeedback";
import { useSelector } from "react-redux";
import { IConfigurationState } from "redux/configuration/configuration.interface";
import { useEffect, useMemo, useState } from "react";
import { formatRemainingTime } from "pages/Collect/core/collect.helpers";
import { getPrizeExpirationDate } from "utils";
import dayjs from "dayjs";

export default function CollectBarCode() {
  const [isVisible, showModal, , reference] = useModal();
  const { reward } = useSelector((state: IConfigurationState) => state);

  console.log("reward", reward);

  const [currentTime, setCurrentTime] = useState<number>(dayjs().unix());
  const [timeUp, setTimeUp] = useState<boolean>(false);

  const claimTimeLimit = useMemo(() => {
    return dayjs(reward?.ra || undefined)
      .add(5, "minutes")
      .unix();
  }, [reward?.ra]);

  useEffect(() => {
    const interval = setInterval(() => {
      const remainingTime = claimTimeLimit - currentTime;
      if (remainingTime <= 0) {
        setTimeUp(true);
        return;
      }
      setCurrentTime(dayjs().unix());
    }, 1000);

    return () => clearInterval(interval);
  }, [currentTime, claimTimeLimit]);

  return (
    <Layout className="game">
      <CouponDiv hideTopCircle circleColor="bg-[var(--primary-light)]">
        <div className="flex py-7 w-full items-center justify-center flex-col gap-5">
          Vous avez gagné un
          <div className="text-[var(--primary)] font-bold uppercase text-2xl">
            {reward?.n || "Reward"}
          </div>
        </div>
      </CouponDiv>
      <CouponDiv circleColor="bg-[var(--primary-light)]">
        <div className="flex border-t-4 border-[var(--primary)] border-dashed w-full items-center justify-center flex-col gap-5">
          <div className="w-full border-t border-[var(--primary)] bg-[var(--primary)] text-white py-2">
            Valable pendant:{" "}
            {formatRemainingTime(timeUp ? 0 : claimTimeLimit - currentTime)} min
          </div>
        </div>

        {reward?.bc && (
          <div className="flex items-center justify-center">
            {/*@ts-ignore */}
            <Barcode width={2} value={reward?.code || "123456789"} />
          </div>
        )}
      </CouponDiv>
      <CouponDiv circleColor="bg-[var(--primary-light)] ">
        <div className="border-t-4 border-dashed border-[var(--primary-light)] py-6 flex flex-col gap-3">
          <p className="text-sm">Code promo à activer :</p>
          <p className="text-[var(--primary)]">{reward?.code || "123456789"}</p>
        </div>
      </CouponDiv>
      <CouponDiv hideBottomCircle circleColor="bg-[var(--primary-light)]">
        <div className="flex flex-col border-t-4 border-dashed border-[var(--primary-light)] gap-1 items-center justify-center py-3">
          <p className="font-semibold">Notes et Conditions </p>
          <p className="text-sm">
            Lot activé le :{" "}
            <span className="font-medium">
              {formatDateWithFrenchTime(reward?.wa || "2024-12-31T23:59:59")}
            </span>
            <br />
            {reward?.pc?.minimumAmount && reward?.pc?.minimumAmount > 0 ? (
              <>
                {" "}
                {`Valable pour tout achat supérieur à ${reward?.pc?.minimumAmount}€`}
                <br />
              </>
            ) : null}
            dans votre {reward?.cn || "store"}.
            <br />
            Offre valable juqu&apos;au{" "}
            {reward?.ct
              ? dayjs(reward?.ct).format("DD/MM/YYYY")
              : getPrizeExpirationDate(
                  reward?.pc?.duration || 1,
                  reward?.pc?.period || 1,
                  reward?.wa || "2024-12-31T23:59:59"
                )}
            {reward?.pc?.eligibility && <div>{reward?.pc?.eligibility}</div>}
          </p>
        </div>
      </CouponDiv>
      <Button
        onClick={showModal}
        text="J'ai récupéré mon lot"
        appearance="primary"
        className="mt-5"
      />
      <Modal
        content={<ModalCollectFeedback />}
        isVisible={isVisible}
        reference={reference}
      />
    </Layout>
  );
}

function formatDateWithFrenchTime(dateString: string): string {
  try {
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      throw new Error("Invalid date string");
    }

    // Pad single digits with leading zero
    const pad = (num: number): string => num.toString().padStart(2, "0");

    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1); // Months are 0-based
    const year = date.getFullYear();
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());

    return `${day}/${month}/${year} à ${hours}:${minutes}`;
  } catch (error) {
    console.error("Error formatting date:", error);
    return "";
  }
}
