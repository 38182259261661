import { useCallback, useEffect } from "react";
import { LeadField } from "redux/configuration/configuration.interface";
import Select from "./Select";

type Props = {
  leadFiled: LeadField;
  setFieldValue: (fieldId: string, value: string) => void;
  error?: string;
  value: string;
};

export default function InfoField(props: Props) {
  const { leadFiled, value, setFieldValue, error } = props;

  const Header = useCallback(() => {
    return (
      <p className="block text-sm font-medium text-left mb-1 ">
        {leadFiled.label}

        {leadFiled.required && <span className="text-red-500">*</span>}
      </p>
    );
  }, [leadFiled.label, leadFiled.required]);

  useEffect(() => {
    if (leadFiled.type === "select") {
      if (leadFiled.options) {
        setFieldValue(leadFiled.id, leadFiled.options[0]);
      }
    }
  }, [leadFiled, setFieldValue]);

  if (leadFiled.type === "select") {
    return (
      <div>
        <Header />
        <Select
          options={leadFiled.options || []}
          value={value}
          onChange={(value) => setFieldValue(leadFiled.id, value)}
        />
      </div>
    );
  }

  return (
    <div>
      <Header />
      <input
        name={leadFiled.id}
        id={leadFiled.id}
        type={leadFiled.type}
        maxLength={leadFiled.maxLength}
        minLength={leadFiled.minLength}
        required={leadFiled.required}
        onChange={(e) => setFieldValue(leadFiled.id, e.target.value)}
        className={`w-full p-2 border rounded-md bg-white h-[48px] text-sm ${
          error ? "border-red-500" : "border-gray-300 "
        }`}
      />
      {error && <div className="text-red-500 text-sm text-left">{error}</div>}
    </div>
  );
}
