import { MouseEventHandler } from "react";
import { getPrizeExpirationDate } from "utils";
import dayjs from "dayjs";
import { GameRewardInfo } from "redux/game/game.interface";

export const handleCTAClick = (
  showModalCollectFeedback: () => void
): MouseEventHandler => {
  const handler: MouseEventHandler = () => {
    showModalCollectFeedback();
  };

  return handler;
};

export function formatRemainingTime(seconds: number): string {
  // Ensure seconds is not negative
  const remainingSeconds = Math.max(0, Math.floor(seconds));

  // Calculate minutes and remaining seconds
  const minutes = Math.floor(remainingSeconds / 60);
  const secondsLeft = remainingSeconds % 60;

  // Pad with leading zeros if needed
  const formattedSeconds = secondsLeft.toString().padStart(2, "0");

  return `${minutes}:${formattedSeconds}`;
}

export const isRewardExpired = (reward: GameRewardInfo | null) => {
  if (reward === null) {
    return true;
  }

  const expiration = getPrizeExpirationDate(
    reward?.pc.duration,
    reward?.pc.period,
    reward?.wa
  );
  if (expiration && reward) {
    return dayjs().isAfter(dayjs(expiration));
  }
  return true;
};

export const canClaimTheReward = (
  wonDate: string | undefined,
  instantAvailability: number | undefined,
  hasCollectDate?: boolean
) => {
  const wonDateToZero = dayjs(wonDate).hour(0).minute(0).second(0);
  console.log(
    "log from can claim reward date check",
    dayjs(dayjs()).diff(wonDateToZero, "day") >= (hasCollectDate ? 0 : 1)
  );

  if (!wonDate) return false;
  if (
    instantAvailability === 1 ||
    dayjs(dayjs()).diff(wonDateToZero, "day") >= (hasCollectDate ? 0 : 1)
  ) {
    return true;
  }
  return false;
};

export const getClaimRewardAvailableDate = (
  wonDate: string | undefined,
  forcedDate?: boolean
) => {
  return dayjs(wonDate)
    .hour(0)
    .minute(0)
    .second(0)
    .add(forcedDate ? 0 : 1, "day")
    .format("DD/MM/YYYY");
};
