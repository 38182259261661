import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import {
  IAPIConfiguration,
  IAPIWinner,
  IConfigurationState,
} from "./configuration.interface";

export enum ActionTypes {
  SET_FORM_SUBMIT_STATUS = "SET_FORM_SUBMIT_STATUS",
  SET_CONFIGURATION = "SET_CONFIGURATION",
  SET_CONFIGURATION_SUCCESS = "SET_CONFIGURATION_SUCCESS",
  SET_CONFIGURATION_ERROR = "SET_CONFIGURATION_ERROR",
  SET_WINNERS = "SET_WINNERS",
  SET_WINNERS_SUCCESS = "SET_WINNERS_SUCCESS",
  SET_WINNERS_ERROR = "SET_WINNERS_ERROR",
  SET_WINNERS_COUNT = "SET_WINNERS_COUNT",
  SET_IS_COMING_FROM_HOME = "SET_IS_COMING_FROM_HOME",
  SET_FORM_FILLED = "SET_FORM_FILLED",
  SET_SKIP_FROM_STARTUP = "SET_SKIP_FROM_STARTUP",
}

interface SetSkipFormStartup {
  type: typeof ActionTypes.SET_SKIP_FROM_STARTUP;
}
interface SetFormSubmitStatusAction {
  type: typeof ActionTypes.SET_FORM_SUBMIT_STATUS;
  payload: { cs: boolean };
}
interface SetConfigutationAction {
  type: typeof ActionTypes.SET_CONFIGURATION;
}

interface SetIsComingFromHome {
  type: typeof ActionTypes.SET_IS_COMING_FROM_HOME;
}

interface SetConfigutationSuccessAction {
  type: typeof ActionTypes.SET_CONFIGURATION_SUCCESS;
  payload: IAPIConfiguration;
}

interface SetConfigutationErrorAction {
  type: typeof ActionTypes.SET_CONFIGURATION_ERROR;
}

interface SetWinnersAction {
  type: typeof ActionTypes.SET_WINNERS;
}

interface SetFormFilledAction {
  type: typeof ActionTypes.SET_FORM_FILLED;
}

interface SetWinnersSuccessAction {
  type: typeof ActionTypes.SET_WINNERS_SUCCESS;
  payload: IAPIWinner[];
}

interface SetWinnersErrorAction {
  type: typeof ActionTypes.SET_WINNERS_ERROR;
}

interface SetWinnersCountAction {
  type: typeof ActionTypes.SET_WINNERS_COUNT;
  payload: number;
}

interface SetConfigutationAction {
  type: typeof ActionTypes.SET_CONFIGURATION;
}

interface SetConfigutationSuccessAction {
  type: typeof ActionTypes.SET_CONFIGURATION_SUCCESS;
  payload: IAPIConfiguration;
}

interface SetConfigutationErrorAction {
  type: typeof ActionTypes.SET_CONFIGURATION_ERROR;
}

interface SetWinnersAction {
  type: typeof ActionTypes.SET_WINNERS;
}

interface SetWinnersSuccessAction {
  type: typeof ActionTypes.SET_WINNERS_SUCCESS;
  payload: IAPIWinner[];
}

interface SetWinnersErrorAction {
  type: typeof ActionTypes.SET_WINNERS_ERROR;
}

export type ConfigurationActionType =
  | SetConfigutationAction
  | SetConfigutationSuccessAction
  | SetConfigutationErrorAction
  | SetWinnersAction
  | SetWinnersSuccessAction
  | SetWinnersErrorAction
  | SetWinnersCountAction
  | SetWinnersCountAction
  | SetIsComingFromHome
  | SetFormSubmitStatusAction
  | SetFormFilledAction
  | SetSkipFormStartup;

// Dispatch type to be used for think actions
export type AppThunkDispatch = ThunkDispatch<
  IConfigurationState,
  any,
  AnyAction
>;
