import Button from "components/Inputs/Button";
import Layout from "components/Layout";
import { FC, MouseEventHandler, useState, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useModal } from "utils/hookedComponents/Modal/core/modal.hook";
import { Context } from "./core/game.context";
import { IContext } from "./core/game.types";
import { useDispatch, useSelector } from "react-redux";
import { handleCTAClick } from "./core/game.helpers";
import { useRedirect } from "utils/hooks/useRedirect";
import { UsePlayEvent } from "utils/hooks/usePlayEvent";
import SpinGame from "components/SpinGame/SpinGame";
import { GAME_ROUTES, GameRoutes } from "utils/constants/router";
import { IConfigurationState } from "redux/configuration/configuration.interface";
import { isDev } from "utils";
import AlreadyPlayedModal from "./AlreadyPlayedModal";

export { Context as GameContext } from "./core/game.context";

const Game: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const game = useSelector((state: IConfigurationState) => state.game);
  const { configuration } = useSelector((state: IConfigurationState) => state);
  const [isVisible, showModal, hideModal, reference] = useModal();
  const [isGameStarted, setIsGameStarted] = useState<boolean>(false);
  const [isPlayButtonPressed, setIsPlayButtonPressed] = useState(false);
  const { t } = useTranslation("game");
  const { entityId } = useParams();

  useRedirect();

  useEffect(() => {
    if (isGameStarted || (game.won == null && !isDev())) {
      return;
    }

    setIsGameStarted(true);
    setTimeout(() => {
      navigate(
        game.won || isDev()
          ? GAME_ROUTES.get(GameRoutes.prize).path
          : GAME_ROUTES.get(GameRoutes.loss).path
      );
    }, 4000); // duration of the spin animation
  }, [game.won, isGameStarted, navigate, setIsGameStarted]);

  const context: IContext = {
    isVisible,
    showModal,
    hideModal,
    reference,
  };

  UsePlayEvent({
    campaignId: entityId,
    isPlaying: isGameStarted,
    hasWon: game.won,
  });

  const handlePlay: MouseEventHandler = () => {
    setIsPlayButtonPressed(true);
    handleCTAClick(dispatch, entityId);
  };

  return (
    <Context.Provider value={context}>
      <Layout className="game">
        <h2 className="mb-3">
          <Trans
            i18nKey="title"
            ns="game"
            components={[<span className="c-primary"></span>]}
          />
        </h2>
        <p className="mb-3 c-grey-800">{t("subtitle")}</p>
        <SpinGame className="mb-7" isGameStarted={isGameStarted} />
        <Button
          disabled={isPlayButtonPressed || !game?.canPlayAgain?.c}
          onClick={handlePlay}
          text={t("CTA")}
        />
        {!configuration?.s?.bkg && (
          <>
            <img
              className="home__doodle home__doodle--1"
              src="/static/images/doodles/doodle-1.png"
              alt="doodle"
            />
            <img
              className="home__doodle home__doodle--2"
              src="/static/images/doodles/doodle-2.png"
              alt="doodle"
            />
          </>
        )}
      </Layout>
      <Outlet />
      {/* run only for lead and only on start up i.e. button upressed since can play value will be updated on press */}
      {configuration?.c?.ctid === 2 && !isPlayButtonPressed && (
        <AlreadyPlayedModal />
      )}
    </Context.Provider>
  );
};

export default Game;
