import { ActionTypes } from "./game.types";
import { IDispatch } from "redux/store";
import axios from "services/axios.service";
import { setSession } from "../session/session.actions";
import { endpoints } from "config/endpoints";
import { track } from "utils";
import { MyliEventType } from "utils/types/event";

export const setPlay = (entityId: string) => async (dispatch: IDispatch) => {
  const { data } = await axios.api.post(
    endpoints.api.game.play,
    {
      cid: entityId,
    },
    { withCredentials: true }
  );

  dispatch({
    type: ActionTypes.SET_GAME_PLAY,
    payload: data,
  });
  console.log("setPlay running", data);
  dispatch({
    type: ActionTypes.SET_GAME_CAN_PLAY_AGAIN,
    payload: {
      c: data.canPlayAgain,
    },
  });
};

export const setCanPlayAgain = (entityId: string) => async (dispatch: any) => {
  try {
    const { data } = await axios.api.get(endpoints.api.game.canPlayAgin, {
      params: {
        cid: entityId,
      },
    });
    dispatch({
      type: ActionTypes.SET_GAME_CAN_PLAY_AGAIN,
      payload: data,
    });
  } catch (error) {
    if (error.request.status === 403) {
      dispatch(setSession("fr", null));
    }
  }
};

export const resetGame =
  (entityId: string | undefined) => (dispatch: IDispatch) => {
    track(MyliEventType.GAME_RESTART, {
      campaignId: entityId,
    });
    dispatch({
      type: ActionTypes.RESET_GAME,
    });
  };

export const setRewardValidation =
  (cid: string, rid: string) => async (dispatch: IDispatch) => {
    // Todo : post when available
    const { data } = await axios.api.post(endpoints.api.reward.validate, {
      cid,
      rid,
    });

    dispatch({
      type: ActionTypes.SET_GAME_REWARD_VALIDATED,
      payload: data,
    });
  };

export const setRewardInfo =
  (rid: string, s: string) => async (dispatch: IDispatch) => {
    try {
      const { data } = await axios.api.get(endpoints.api.reward.info, {
        params: {
          rid,
          s,
        },
      });

      dispatch({
        type: ActionTypes.SET_GAME_REWARD_INFO,
        payload: { ...data, isFetched: true },
      });
    } catch (err) {
      dispatch({
        type: ActionTypes.SET_GAME_REWARD_INFO_ERROR,
        payload: { isFetched: true, hasError: true },
      });
    }
  };
