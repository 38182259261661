import { useCallback, useState } from "react";

type Props = {
  options: string[];
  onChange: (store: string) => void;
  value: string;
};

export default function Select(props: Props) {
  const { onChange, options, value } = props;

  const [open, setOpen] = useState(false);

  const handleClick = useCallback(
    (store: string) => {
      onChange(store);
      setOpen(false);
    },
    [onChange]
  );

  return (
    <>
      {open && (
        <div
          className="absolute top-0 left-0 w-screen h-screen z-10"
          onClick={() => setOpen(false)}
        />
      )}
      <div className="relative w-full  ">
        {/* input and icon */}
        <section className="flex gap-1">
          <div className="relative grow">
            <div
              onClick={() => {
                setOpen(!open);
              }}
              className="w-full text-sm flex items-center  px-2 border border-gray-300 rounded-md bg-white h-[48px]"
            >
              {value}
            </div>
            <svg
              className="absolute right-2 top-1/2 -translate-y-1/2"
              width={12}
              height={14}
              viewBox="0 0 12 14"
            >
              <path
                d="M6 0L12 6H0L6 0Z"
                style={{
                  fill: "var(--primary)",
                }}
              />
              <path
                d="M6 14L12 8H0L6 14Z"
                style={{
                  fill: "var(--primary)",
                }}
              />
            </svg>
          </div>
        </section>
        {/* popup section */}
        {open && (
          <section className="top-[50px] z-10 w-full border border-x-gray-300 bg-white rounded-md absolute max-h-[200px] overflow-auto">
            {options.map((op) => (
              <div
                key={op}
                onClick={() => {
                  handleClick(op);
                  setOpen(false);
                }}
                className="p-2 cursor-pointer text-sm  hover:bg-gray-100 text-left"
              >
                {op}
              </div>
            ))}
          </section>
        )}
      </div>
    </>
  );
}
