import { Dispatch, MouseEventHandler } from "react";
import { setPlay } from "redux/game/game.actions";

export const handleCTAClick = (
  dispatch: Dispatch<any>,
  entityId: string | undefined
): MouseEventHandler => {
  if (!entityId) {
    return;
  }
  console.log("handleCTAClick", entityId);
  dispatch(setPlay(entityId));
};
