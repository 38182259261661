import Icon from "components/Icon";
import { FC } from "react";
import { Icons } from "utils/constants/icons";
import { handleClick } from "./core/button.helpers";
import { IProps } from "./core/button.types";

const Button: FC<IProps> = ({
  text,
  onClick,
  className,
  appearance = "primary",
  icon,
  loading = false,
  disabled = false,
  small = false,
  style,
  useDisableDark = false,
}) => {
  return (
    <button
      style={style}
      disabled={disabled}
      className={`btn btn--${appearance} ${
        disabled || loading
          ? useDisableDark
            ? "btn--disabled-dark "
            : "btn--disabled"
          : ""
      } ${small ? "btn--small" : ""} ${className || ""}`}
      onClick={handleClick(onClick, disabled, loading)}
      type="button"
    >
      {text}
      {icon && <Icon className="btn__icon" icon={icon} />}
      {loading && <Icon className="btn__icon" icon={Icons.loading} />}
    </button>
  );
};

export default Button;
