import { FC, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useModal } from "utils/hookedComponents/Modal/core/modal.hook";
import { Context } from "./core/home.context";
import { handleCTAClick } from "./core/home.helpers";
import { IContext } from "./core/home.types";
import { useSelector, useDispatch } from "react-redux";
import { IConfigurationState } from "redux/configuration/configuration.interface";
import Layout from "components/Layout";
import Carousel from "components/Carousel";
import Button from "components/Inputs/Button";
import SpinGame from "components/SpinGame/SpinGame";
import { Trans, useTranslation } from "react-i18next";
import { Icons } from "utils/constants/icons";
import { useInternationalization } from "utils/hooks/useInternationalization";
import { deviceIsMobile, isDev } from "utils";
import i18n from "utils/internationalization";
import { useColor } from "utils/hooks/useColor";
import Winner from "components/Winner";
import Desktop from "pages/Desktop";
import CarouselAutoSwiping from "components/Carousel/composition/CarouselAutoSwiping";
import { useRedirect } from "utils/hooks/useRedirect";
import { setCanPlayAgain } from "redux/game/game.actions";
import { AppThunkDispatch } from "redux/configuration/configuration.types";
import { fetchConfiguration } from "redux/configuration/configuration.actions";
import { setSession } from "redux/session/session.actions";

export { Context as HomeContext } from "./core/home.context";

const Home: FC = () => {
  const dispatch: AppThunkDispatch = useDispatch();
  const { isReady, configuration, winners, game, session, fromFilled } =
    useSelector((state: IConfigurationState) => {
      return state;
    });

  const { canPlayAgain } = game;
  const [isVisible, showModal, hideModal, reference] = useModal();
  const [isGameStarted, setIsGameStarted] = useState<boolean>(false);

  const navigate = useNavigate();
  const { t } = useTranslation("home");
  const { entityId } = useParams();
  const isMobile = deviceIsMobile();

  useEffect(() => {
    if (!configuration || (!canPlayAgain?.c && !isDev())) return;
    if (configuration?.c?.ctid !== 2) return;
    if (!fromFilled) {
      navigate(`/${entityId}/form`);
    } else if (!configuration?.ojs || configuration?.ojs?.length === 0) {
      navigate(`/${entityId}/game`);
    }
  }, [navigate, fromFilled, configuration, entityId, canPlayAgain]);

  useEffect(() => {
    if (entityId) {
      // && session) { REMOVED TO FORCE CAN PLAY AGAIN FETCH
      dispatch(setCanPlayAgain(entityId));
    }
  }, [dispatch, entityId, session]);

  useEffect(() => {
    if (entityId) {
      dispatch(fetchConfiguration(entityId));
    }
  }, [dispatch, entityId]);

  const locale = configuration?.c?.lg[0];
  const antiFraudType = configuration?.c?.aft;

  useEffect(() => {
    if (locale) {
      dispatch(setSession(locale, antiFraudType));
    }
  }, [dispatch, locale, antiFraudType]);

  useEffect(() => {
    if (isReady && configuration) {
      setTimeout(() => {
        setIsGameStarted(true);
      }, 500);
    } else if (isReady && !configuration) {
      navigate("/404");
    }
  }, [isReady, configuration, setIsGameStarted, navigate]);

  const context: IContext = {
    isVisible,
    showModal,
    hideModal,
    reference,
  };

  useRedirect();
  useColor({ primaryColor: configuration?.s.pColor });
  useInternationalization({
    i18n,
    contents: configuration?.c.contents,
    locale: configuration?.c?.lg[0],
  });

  console.log("configuration", configuration);
  console.log("can play again", canPlayAgain);
  // console.log("session", session);
  // console.log(
  //   "disabled button:",
  //   "ets:",
  //   configuration?.ets,
  //   "canPlayAgain:",
  //   canPlayAgain,
  //   "isDev:",
  //   isDev(),
  //   "Full disable value:",
  //   !configuration?.ets && canPlayAgain == null && !isDev()
  // );

  return (
    <Context.Provider value={context}>
      {isMobile ? (
        isReady &&
        configuration && (
          <Layout className="home">
            {winners.length > 0 && (
              <Carousel className="mb-7">
                <CarouselAutoSwiping speed={2350} />
                {winners.map((winner, index) => (
                  <Carousel.Item key={index}>
                    <Winner winner={winner} />
                  </Carousel.Item>
                ))}
              </Carousel>
            )}
            <div className="home__game-container f f-direction-column">
              <SpinGame isGameStarted={isGameStarted} />
              <h1 className="ft-up pt-7 f f-direction-column mb-2">
                <Trans
                  ns="home"
                  i18nKey="title"
                  components={[<span className="c-primary"></span>]}
                  values={{
                    restaurant: configuration?.dn || t("companyNameFallback"),
                  }}
                />
              </h1>
              <p className="mb-5 fw-500">
                {t("subtitle", { company: configuration?.c.name })}
              </p>
              <Button
                appearance="primary"
                text={t("CTA")}
                onClick={handleCTAClick(
                  navigate,
                  canPlayAgain?.obj?.t || 0,
                  configuration.ets
                )}
                icon={Icons.chevronRight}
                disabled={
                  !configuration?.ets && canPlayAgain == null && !isDev()
                }
              />
            </div>
            {!configuration?.s?.bkg && (
              <>
                <img
                  className="home__doodle home__doodle--1"
                  src="/static/images/doodles/doodle-1.png"
                  alt="doodle"
                />
                <img
                  className="home__doodle home__doodle--2"
                  src="/static/images/doodles/doodle-2.png"
                  alt="doodle"
                />
              </>
            )}
          </Layout>
        )
      ) : (
        <Desktop />
      )}
      <Outlet />
    </Context.Provider>
  );
};

export default Home;
