import { UserState } from "redux/user/user.interface";
import { GameRewardInfo, IStoreGame } from "redux/game/game.interface";
import { AntiFraudType } from "utils/types/session";

export interface IAPITranslation {
  key: string;
  text: string;
}

export interface IAPIReward {
  name: string;
  couponCode: string;
  picture: string;
}

export enum IAPIDataHarvest {
  LASTNAME = 0,
  FIRSTNAME = 1,
  EMAIL = 2,
  PHONENUMBER = 3,
}

export enum IAPIObjective {
  REVIEW_GOOGLE = 0,
  FREEQUESTION = 1,
  FOLLOW_INSTAGRAM = 2,
  FOLLOW_TIKTOK = 3,
  NPS = 4,
  REFER_FRIEND = 5,
  USER_CONTENT_CREATION = 6,
  FOLLOW_TWITTER = 7,
  NEWSLETTER = 8,
  GOOGLE_B = 9,
  FOLLOW_FACEBOOK = 10,
  VISIT_TRIPADVISOR = 11,
  DOWNLOAD_APP = 12,
  SURVEY = 13,
  SELECT_RESTAURANT = 100,
  ALERT_STEP = 101,
}

export enum MyliPlatformId {
  TRIPADVISOR = 1,
  GOOGLE = 7,
  FACEBOOK = 8,
  GOOGLEPLACE = 9,
  INSTAGRAM = 132,
  TWITTER = 131,
  TIKTOK = 133,
  DOWNLOAD_APP_IOS = 134, // To be changed with the real ID when added by backend
  DOWNLOAD_APP_ANDROID = 135, // To be changed with the real ID when added by backend
  SURVEY = 136, // To be changed with the real ID when added by backend
}

export interface IAPIEstablishment {
  cid: string; // Unique identifier
  n: string; // Name
  sa: string; // Street Address
  sat: string | null; // Street Address Two
  c: string; // City
  pc: string; // Postal Code,
  lat: number; // Latitude
  lng: number; // Longitude
}

export enum IAPIPeriodAttempt {
  DAILY = "daily",
  WEEKLY = "weeky",
  MONTHLY = "monthly",
  YEARLY = "yearly",
}

export interface IAPIPicture {
  iw: boolean;
  il: boolean;
  pbu: string;
  pru: string;
}

export interface IAPIWinner {
  item: string;
  winnerDisplayName: string;
  winAt: string;
  wa?: string;
}

export interface MarketAction {
  pid: MyliPlatformId;
  u: string;
}

export interface Configuration {
  name: string;
  cy: string;
  lg: string[];
  contents: Record<string, IAPITranslation[]>;
  rpdf: string;
  aft: AntiFraudType;
  ctid: number;
}

export interface Settings {
  mlPart: boolean;
  mxAtp: number;
  pAtp: IAPIPeriodAttempt;
  isFW: boolean;
  nbBfFw: number;
  pColor: string;
  dh: number[];
  pictures: IAPIPicture[];
  leadSettings: LeadSettings;
  bkg?: string;
  t?: string;
  st?: string;
  hl?: string;
  ff?: string;
}

export interface LeadField {
  id: string;
  label: string;
  type: string;
  required: boolean;
  options?: string[];
  maxLength?: number;
  minLength?: number;
  section: "contact" | "identity";
}

export interface LeadConsent {
  id: string;
  label: string;
  required: boolean;
}

export interface LeadSettings {
  fields: LeadField[];
  consents: LeadConsent[];
}

export interface IAPIConfiguration {
  dn: string | null;
  name: string;
  s: Settings;
  c: Configuration;
  placeId: string;
  displayFormat: string;
  dh: IAPIDataHarvest[];
  country: string;
  languages: string[];
  ojs: IAPIObjective[];
  rws: IAPIReward[];
  contents: Record<string, IAPITranslation[]>;
  rulesPDF: string;
  ets?: IAPIEstablishment[];
  w: MarketAction[];
}

export interface IConfigurationState {
  isReady: boolean;
  winners: IAPIWinner[];
  session: string | null;
  configuration?: IAPIConfiguration;
  userData: UserState;
  reward: GameRewardInfo | null;
  game: IStoreGame;
  isHappy: boolean;
  winnersCount: number;
  isComingFromHome: boolean;
  fromFilled?: boolean;
  skipFromStartup?: boolean;
}
