import { useCallback, useEffect, useMemo, useState } from "react";
import { IAPIEstablishment } from "redux/configuration/configuration.interface";

type Props = {
  storeList: IAPIEstablishment[];
  setSelectedStore: (store: string) => void;
};

export default function StoreSelect(props: Props) {
  const { storeList, setSelectedStore } = props;

  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");

  const filteredStores = useMemo(() => {
    if (search.length < 3) return storeList;
    return storeList.filter(
      (store) =>
        store.n.toLowerCase().includes(search.toLowerCase()) ||
        store.sa.toLowerCase().includes(search.toLowerCase())
    );
  }, [storeList, search]);

  const handleClick = useCallback(
    (store: IAPIEstablishment) => {
      setSelectedStore(store.cid);
      setSearch(store.n);
      setOpen(false);
    },
    [setSelectedStore]
  );

  const getClosestStore = useCallback(() => {
    if (!navigator.geolocation) {
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const userLat = position.coords.latitude;
        const userLng = position.coords.longitude;

        // Find the closest store
        let closestStore = storeList[0];
        let minDistance = getDistance(
          userLat,
          userLng,
          closestStore.lat,
          closestStore.lng
        );

        storeList.forEach((store) => {
          const distance = getDistance(userLat, userLng, store.lat, store.lng);
          if (distance < minDistance) {
            minDistance = distance;
            closestStore = store;
          }
        });

        // Update the selected store
        handleClick(closestStore);
      },
      (error) => {
        console.error("Error getting location:", error);
      }
    );
  }, [storeList, handleClick]);

  // Open the popup if the search is more than 2 characters
  useEffect(() => {
    if (search.length > 2) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [search]);

  return (
    <>
      {open && (
        <div
          className="absolute top-0 left-0 w-screen h-screen z-10"
          onClick={() => setOpen(false)}
        />
      )}
      <div className="relative w-full my-6">
        {/* input and icon */}
        <section className="flex gap-1">
          <div className="relative grow">
            <input
              value={search}
              onFocus={() => search.length > 2 && setOpen(true)}
              onChange={(e) => setSearch(e.target.value)}
              type="text"
              placeholder="Sélectionnez votre magasin"
              className="w-full p-2 border border-gray-300 rounded-md bg-white h-[48px]"
            />
            <svg
              className="absolute right-2 top-1/2 -translate-y-1/2"
              width={12}
              height={14}
              viewBox="0 0 12 14"
            >
              <path
                d="M6 0L12 6H0L6 0Z"
                style={{
                  fill: "var(--primary)",
                }}
              />
              <path
                d="M6 14L12 8H0L6 14Z"
                style={{
                  fill: "var(--primary)",
                }}
              />
            </svg>
          </div>
          <button onClick={getClosestStore}>
            <svg width={24} height={24} viewBox="0 0 24 24">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.94 11C20.48 6.83 17.17 3.52 13 3.06V2C13 1.45 12.55 1 12 1C11.45 1 11 1.45 11 2V3.06C6.83 3.52 3.52 6.83 3.06 11H2C1.45 11 1 11.45 1 12C1 12.55 1.45 13 2 13H3.06C3.52 17.17 6.83 20.48 11 20.94V22C11 22.55 11.45 23 12 23C12.55 23 13 22.55 13 22V20.94C17.17 20.48 20.48 17.17 20.94 13H22C22.55 13 23 12.55 23 12C23 11.45 22.55 11 22 11H20.94ZM12 8C9.79 8 8 9.79 8 12C8 14.21 9.79 16 12 16C14.21 16 16 14.21 16 12C16 9.79 14.21 8 12 8ZM5 12C5 15.87 8.13 19 12 19C15.87 19 19 15.87 19 12C19 8.13 15.87 5 12 5C8.13 5 5 8.13 5 12Z"
                style={{
                  fill: "var(--primary)",
                }}
              />
            </svg>
          </button>
        </section>
        {/* popup section */}
        {open && (
          <section className="top-[50px] z-10 w-[calc(100vw-60px)] border border-x-gray-300 bg-white rounded-md absolute max-h-[200px] overflow-auto">
            {[...filteredStores].map((store) => (
              <div
                key={store.cid}
                onClick={() => {
                  handleClick(store);
                  setOpen(false);
                }}
                className="p-2 cursor-pointer  hover:bg-gray-100 text-left"
              >
                {store.n}
                <p className="text-sm opacity-60">{store.sa}</p>
              </div>
            ))}
          </section>
        )}
      </div>
    </>
  );
}

const getDistance = (
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) => {
  const R = 6371; // Earth's radius in kilometers
  const dLat = ((lat2 - lat1) * Math.PI) / 180;
  const dLon = ((lon2 - lon1) * Math.PI) / 180;
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos((lat1 * Math.PI) / 180) *
      Math.cos((lat2 * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in kilometers
};
