import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import StepNavigationStep from "components/StepNavigation/composition/StepNavigationStep";
import { handleCTAClick } from "./core/modalClaimPrizeStepConfirm.helpers";
import Button from "components/Inputs/Button";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IConfigurationState } from "redux/configuration/configuration.interface";
import { IProps } from "./core/modalClaimPrizeStepConfirm.types";

const ModalClaimStepPrizeConfirm: FC<IProps> = ({ step = 2 }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("modals", { keyPrefix: "prize.confirm" });
  const { game } = useSelector((state: IConfigurationState) => state);
  const dispatch = useDispatch();
  const { entityId } = useParams();

  return (
    <StepNavigationStep className="modal__content" step={step}>
      <div className="modal__img">
        <img src="/static/images/modals/mailbox.png" alt="mailbox emoji" />
      </div>
      <h2 className="modal__title">
        <Trans
          ns="modals"
          i18nKey="prize.confirm.title"
          components={[<span className="c-primary"></span>]}
        />
      </h2>
      <p>
        <Trans
          ns="modals"
          i18nKey="prize.confirm.subtitle"
          components={[<span className="c-primary fw-700"></span>]}
        />
      </p>
      <p className="mb-3">{t("subtitle2")}</p>
      {game.canPlayAgain?.c && (
        <>
          <p className="mb-3">{t("subtitle3")}</p>

          <p className="fs-x-small fw-500 c-grey-600">{t("disclaimer")}</p>
        </>
      )}

      {game?.canPlayAgain?.c ? (
        <Button
          className="modal__btn"
          disabled={!game.canPlayAgain?.c}
          onClick={handleCTAClick(navigate, dispatch, entityId || "404")}
          text={t("CTA")}
        />
      ) : (
        <p>{t("CTACantPlayAgain")}</p>
      )}
    </StepNavigationStep>
  );
};

export default ModalClaimStepPrizeConfirm;
