import { Marketings } from "pages/Home/core/home.types";
import { IProps } from "./modalConfig.types";
import { NavigateFunction } from "react-router-dom";

export const onRestaurantSelect = (
  restaurant: any,
  navigate: NavigateFunction
) => {
  navigate(`/${restaurant.cid}`);
};

export const getNumberOfSteps = (marketing: IProps["marketing"]): any => {
  switch (marketing) {
    case Marketings.newsletter:
      return 4;
    case Marketings.googlePlace:
      return 2;
    default:
      return 1;
  }
};
