import Button from "components/Inputs/Button";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Icons } from "utils/constants/icons";
import Modal from "utils/hookedComponents/Modal";
import { useModal } from "utils/hookedComponents/Modal/core/modal.hook";
import ModalWinners from "../ModalWinners";
import {
  handleCTAClick,
  handleSeeWinnersClick,
} from "./core/modalClaimLoss.helpers";
import { IProps } from "./core/modalClaimLoss.types";
import { useDispatch, useSelector } from "react-redux";
import { IConfigurationState } from "redux/configuration/configuration.interface";

const ModalClaimLoss: FC<IProps> = ({
  numberOfTries,
  maxAttemps,
  isFreeWin,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation("modals", { keyPrefix: "loss" });
  const [isVisible, showModal, hideModal, reference] = useModal();
  const { entityId } = useParams();
  const { winnersCount } = useSelector((state: IConfigurationState) => state);
  const dispatch = useDispatch();
  const isNextFreeWin = numberOfTries + 2 >= maxAttemps && isFreeWin;
  const game = useSelector((state: IConfigurationState) => state.game);

  const canPlayAgain = useMemo(() => {
    return !!game.canPlayAgain.c;
  }, [game.canPlayAgain.c]);

  return (
    <div className="modal__content bg-sparkles">
      <div className="modal__img">
        <img src="/static/images/modals/cry.png" alt="crying emoji" />
      </div>
      <h2 className="modal__title">
        {isNextFreeWin ? t("titleBis") : t("title")}
      </h2>

      {canPlayAgain && (
        <p>{isNextFreeWin ? t("subtitleBis") : t("subtitle")}</p>
      )}
      {canPlayAgain && (
        <Button
          onClick={handleCTAClick(navigate, dispatch, entityId)}
          icon={Icons.chevronRight}
          text={isNextFreeWin ? t("CTABis") : t("CTA")}
          className="modal__btn mb-4"
        />
      )}

      {winnersCount > 0 && (
        <p
          className="fw-700 c-primary mb-3"
          onClick={handleSeeWinnersClick(showModal)}
        >
          {t("seeWinners", {
            count: winnersCount,
          })}
        </p>
      )}

      <Modal
        reference={reference}
        isVisible={isVisible}
        content={<ModalWinners hideModal={hideModal} />}
        className="modal--winners"
      />
    </div>
  );
};

export default ModalClaimLoss;
