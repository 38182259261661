import { FC, useContext } from "react";
import { useNavigate } from "react-router-dom";
import StepNavigationStep from "components/StepNavigation/composition/StepNavigationStep";
import { handleCTAClick } from "./core/modalConfigStepGoogleB.helpers";
import { IProps } from "./core/modalConfigStepGoogleB.types";
import Button from "components/Inputs/Button";
import { Icons } from "utils/constants/icons";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  IAPIObjective,
  IConfigurationState,
  MyliPlatformId,
} from "redux/configuration/configuration.interface";
import { searchMarketAction } from "pages/Home/core/home.helpers";
// import Icon from "components/Icon";
// import { getMobileOperatingSystem } from "utils";
import ModalHomeInfo from "components/Modals/ModalHomeInfo/ModalHomeInfo";
import { getMobileOperatingSystem, track } from "utils";
import { MyliEventType } from "utils/types/event";
import Icon from "components/Icon";
import { StepNavigationContext } from "components/StepNavigation";

const ModalConfigStepGoogleB: FC<IProps> = ({ hideModal }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("modals", { keyPrefix: "google" });
  const stepNavigationContext = useContext(StepNavigationContext);
  const { nextStep } = stepNavigationContext;

  const { configuration } = useSelector((state: IConfigurationState) => state);
  const marketAction = searchMarketAction(
    MyliPlatformId.GOOGLE,
    configuration?.w
  );

  const os = getMobileOperatingSystem();

  return (
    <>
      <StepNavigationStep className="modal__content" step={2}>
        <ModalHomeInfo
          handleClick={handleCTAClick(hideModal, navigate, marketAction?.u)}
        />
      </StepNavigationStep>
      <StepNavigationStep className="modal__content" step={1}>
        <div className="modal__img">
          <img
            src="/static/images/modals/megaphone.png"
            alt="megaphone emoji"
          />
        </div>

        <div className="flex">
          <div className="badge mr-2">1</div>
          <h4 className="ft-left">
            <Trans
              ns="modals"
              i18nKey="google.firstStep"
              values={{ restaurant: configuration?.name }}
              components={[<span className="c-google"></span>]}
            />
          </h4>
        </div>
        {(os === "iOS" || os === "Android") && (
          <div className="flex">
            <div className="badge mr-2">2</div>
            <h4 className="ft-left">
              <Trans
                ns="modals"
                i18nKey={`google.secondStep.${os}`}
                values={{ restaurant: configuration?.name }}
                components={[<span className="c-google"></span>]}
              />
              {os === "iOS" && (
                <Icon icon={Icons.tab} className=" inline-block ml-2" />
              )}
            </h4>
          </div>
        )}
        <div className="flex">
          <div className="badge mr-2">
            {os === "iOS" || os === "Android" ? 3 : 2}
          </div>
          <h4 className="ft-left">
            <Trans
              ns="modals"
              i18nKey={`google.thirdStep`}
              values={{ restaurant: configuration?.name }}
              components={[<span className="c-google"></span>]}
            />
          </h4>
        </div>

        <p className="fs-small c-grey-600">{t("disclaimer")}</p>
        <Button
          text={t("CTA")}
          onClick={() => {
            track(MyliEventType.ALERT_STEP, {
              objective: IAPIObjective.ALERT_STEP,
            });
            nextStep();
          }}
          appearance="google"
          icon={Icons.chevronRight}
          className="modal__btn"
        />
      </StepNavigationStep>{" "}
    </>
  );
};

export default ModalConfigStepGoogleB;
