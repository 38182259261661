import { Footer } from "components/Footer";
import { FC, useEffect, useRef } from "react";
import { useLayout } from "./core/layout.hook";
import { IProps } from "./core/layout.types";
import { useSelector } from "react-redux";

const Layout: FC<IProps> = ({
  children,
  className,
  hideFooter = false,
  isDesktop = false,
}) => {
  const reference = useRef<HTMLDivElement>(null);

  useLayout({ reference });

  const { configuration } = useSelector((state: any) => state);

  useEffect(() => {
    if (!configuration || !configuration?.s?.ff) return;
    console.log("font update", configuration?.s?.ff);
    const fontName = configuration?.s?.ff;
    const link = document.createElement("link");
    link.href = `https://fonts.googleapis.com/css2?family=${fontName}:wght@400;500;700;800&display=swap`;
    link.rel = "stylesheet";
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, [configuration]);

  return (
    <div
      ref={reference}
      className={`layout ${className || ""} f f-jc-space-b f-direction-column`}
      style={{
        fontFamily: configuration?.s?.ff,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundImage:
          configuration?.s?.bkg && !isDesktop
            ? `url(${configuration?.s?.bkg})`
            : undefined,
      }}
    >
      <div
        className={
          isDesktop
            ? "layout__desktop f f-ai-center"
            : "layout__content f f-direction-column f-jc-center ft-center"
        }
      >
        {children}
      </div>
      {!hideFooter && <Footer />}
    </div>
  );
};

export default Layout;
