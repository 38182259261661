import { FC, useEffect } from "react";
import Modal from "utils/hookedComponents/Modal";
import ModalAlreadyParticipated from "components/Modals/ModalAlreadyParticipated";
import { useModal } from "utils/hookedComponents/Modal/core/modal.hook";
import { useSelector } from "react-redux";
import { IConfigurationState } from "redux/configuration/configuration.interface";

const AlreadyPlayedModal: FC = () => {
  const [isVisible, showModal, , reference] = useModal();

  const game = useSelector((state: IConfigurationState) => state.game);
  console.log("game from modal", game);

  useEffect(() => {
    if (!showModal || game?.canPlayAgain?.c) {
      return;
    }
    showModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [game]);

  return isVisible ? (
    <Modal
      reference={reference}
      isVisible={isVisible}
      content={<ModalAlreadyParticipated />}
    />
  ) : null;
};

export default AlreadyPlayedModal;
