import { useMemo } from "react";
import { LeadConsent } from "redux/configuration/configuration.interface";

type Props = {
  leadConsent: LeadConsent;
  handleConsent: (id: string) => void;
  checked: boolean;
};

export default function ConsentField(props: Props) {
  const { leadConsent, handleConsent, checked } = props;

  const lableWithStyles = useMemo(() => {
    return leadConsent.label.replaceAll(
      "<a ",
      "<a class='text-blue-500 underline' "
    );
  }, [leadConsent.label]);
  return (
    <button
      onClick={() => handleConsent(leadConsent.id)}
      className="flex items-start "
    >
      <div>
        <div className="p-0.5 mr-2 w-5 h-5 rounded-md border border-gray-300 flex items-center justify-center bg-gray-50">
          <div
            className={`transition-all rounded-[4px] ${
              checked ? "bg-[var(--primary)] w-3.5 h-3.5" : "w-0 h-0"
            }`}
          />
        </div>
      </div>
      <p className="font-medium text-sm text-left">
        {/* eslint-disable-next-line react/no-danger */}
        <span dangerouslySetInnerHTML={{ __html: lableWithStyles }} />
        {leadConsent.required && <span className="text-red-500">*</span>}
      </p>
    </button>
  );
}
